.zoom-in-out-box {
    overflow: hidden;
  }
  
  .zoom-in-out-box img {
    border-radius: 2%;
    transition: transform 0.3s ease;
  }
  
  .zoom-in-out-box:hover img {
    transform: scale(1.1);
  }