.app__navbar-menu {
  display: flex;
  flex-direction: column;
  background-color: #f7f9fb;
  color: #0c5db4;
  align-items: center;
  @media screen and (min-width: 900px) {
    display: none;
  }
}
.app__navbar-menuOne {
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  background-color: #25c7d5;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 900px) {
    display: none;
  }
}
.app__navbar-menuOne svg {
  fill: white; /* Change the fill color of the SVG */
  width: 20px; /* Adjust the width of the SVG */
  height: 20px; /* Adjust the height of the SVG */
  transition: fill 0.3s; /* Add transition effect to the fill property */
}
