.fill-animation {
    position: absolute;
    inset: 0;
    background-color: white;
    transform: scaleX(0.05);
    transform-origin: left;
    transition: transform 0.4s;
  }
  
  .border-2 {
    position: relative;
  }
  
  .border-2:hover .fill-animation {
    transform: scaleX(1);
  }
  
  .border-2:hover h1 {
    color: #77838f;
    position: relative;
    z-index: 1;
  }