.fill-anime{
    position: absolute;
    inset: 0;
    background-color: #25C7D5;
    transform: scaley(0.0);
    transform-origin: bottom;
    transition: transform 0.6s;
  }
  .listOne:hover .fill-anime{
    transform: scaley(1);
  }
  .listOne:hover .listTwo {
    position: relative;
    z-index: 1;
  }
  .listOne:hover .Image {
    position: relative;
    z-index: 1;
  }
  .listOne:hover .listThree{
    color: white
  }
  